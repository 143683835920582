@import "~@sartorius/ui/scss/_variables.scss";

.accordion {

  margin-bottom: 2rem;

  .row {
    margin-left: 0;
    margin-right: 0;
    padding: 1.5rem;
    padding-bottom: 1.2rem;
    align-items: center;
    background-color: $gray-600;

    :hover {
      cursor: pointer;
    }

    :global(.h1) {
      line-height: 0;
    }

    .content {
      max-width: calc(100% - 1em - 30px) !important;
      text-overflow: ellipsis;
      padding: 0;
      align-content: center;
    }

    .icon {
      margin-bottom: 0;
      border: 2px solid $black;
    }

    .rightText {
      color: white;
      margin-right: 1rem;
      align-self: center;
      font-size: 14px;
      @media print {
        margin-right: 0;
        width: 30%;
      }
    }

    .arrow {
      margin-bottom: 0;
      color: white;
      align-content: center;
      font-size: 2.5rem;
      @media print {
        display: none;
      }
    }

    .iconCompleted {
      z-index: 10;
      margin-bottom: 0;
      filter: invert(100%);
      background-color: $white;
      border: 2px solid $white;
    }

    .iconWrapper {
      position: relative;
      margin-left: 2rem;
      min-width: 35px;
      align-content: center;
      overflow: hidden;

      .verticalLine {
        position: absolute;
        left: 0;
        right: 0;

        margin-left: auto;
        margin-right: auto;

        width: 2px;
        height: 19.5px;
        background: $black;
      }

      .verticalLine.rotate {
        top: 0;
      }
    }

  }

  .row:hover {
    background-color: $black !important;
    color: $white !important;

    .icon, .iconCompleted, .verticalLine {
      filter: invert(100%);
    }

    .iconCompleted {
      border: 2px solid $black;
    }

  }

  .collapsibleContent {
    position: relative;

    .verticalLineContent {
      position: absolute;
      left: 36.5px;
      right: 0;

      width: 2px;
      height: 100%;
      background: $black;
    }
  }

  .collapsedContent {
    position: relative;

    .verticalLineContent {
      position: absolute;
      left: 36.5px;
      right: 0;

      width: 2px;
      height: 100%;
      background: $black;
    }
  }
}

.rotateArrow {
  transform: rotate(180deg);
}

.mainText {
  font-size: 16px;
  align-content: center;
  font-weight: 500;
  color: white;
}

.collapsedText {
  font-size: 1.5rem;
  margin-bottom: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

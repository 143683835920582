@import "~@sartorius/ui/scss/_variables.scss";
@import '~@coreui/coreui/scss/mixins/breakpoints';

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;

  @media print {
    margin-left: 0.7rem;
  }
}

:global(.c-header) :global(.c-header-brand) .vwrImage {
  height: 4.6rem;
  width: auto !important;
}

.navContainer {
  align-items: stretch;

  li {
    margin-bottom: 0;

    a {
      height: 100%;
      display: flex;
      align-items: center;

      &:hover {
        color: white;
        background: black;
      }
    }
  }
}

.headerText {
  font-size: 2.4rem;
}

.secondaryHeaderText {
  font-size: 1.75rem;
}

.nextButtonAndTextWrapper {
  align-content: center;
}

.circleImg {
  width: 100px;
}

.stepContainer {
  display: flex;
}

.topOfButtonText {
  padding-left: 1rem;
  font-size: 1.4rem;
  margin-bottom: 10px;
}

.nextButtonLabel {
  font-size: 10px;
}
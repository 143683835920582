@import '~@sartorius/ui/scss/variables';

.serviceRow {
  background-color: $gray-200;
  width: 100%;
  display: flex;
  padding: 0.7rem 1.5rem;

  div:first-child {
    flex: 1;
  }

  @media print {
    div:last-child {
      width: 30%;
    }
  }
}

.value {
  img {
    margin-right: .5rem;
    width: 26px;
    height: 26px;
    font-size: 24px;
  }

  .none {
    width: 26px;
    text-align: center;
    margin-right: .5rem;
  }
}

.nested {
  margin-left: 4.5rem;
}
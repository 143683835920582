@import '~@sartorius/ui/scss/variables';

.blackRow {
  background-color: $black;
  color: $white;
  padding: 1.5rem;
  padding-bottom: 1.2rem;
  font-size: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media print {
    padding: .5rem 1.5rem;
    padding-bottom: .2rem;
  };
}

.withoutCursor {
  cursor: default !important;
}

.blackRow:hover {
  color: white;
}

.headerText {
  @media print {
    font-size: 2.5rem;
  }
}

.pencil {
  filter: invert(1);
  float: right;
  width: 1.7rem;
  height: 1.7rem;
  margin-right: 1rem;
}